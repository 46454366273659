
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon{
    color: white !important;
  }
  .MuiTablePagination-actions{
    display: none;
  }
  .center{
    display: flex;
    justify-content: center;
  }
  .MuiTablePagination-toolbar{
    padding: 0 !important;
  }
  .MuiOutlinedInput-multiline{
    padding: 0px !important;
  }
  .MuiTableCell-sizeSmall {
    padding: 5px 24px 5px 16px !important;
  }
  .MuiOutlinedInput-notchedOutline{
    border: none !important;
  }

  .MuiOutlinedInput-root {
    background-color: white !important;
    border: 1px solid #606C7D;
    border-radius: 4px !important;
    width: 228px;
    margin-left: 6px;
}
.MuiOutlinedInput-root.Mui-focused {
    border: 1px solid #606c7d !important;
    box-shadow: 0 0 4px 0px #009bef !important;
}
.MuiOutlinedInput-root.Mui-error{
    border: 2px solid #d35318 !important;
    background-color: #faede7 !important;
  }
.sideNav {
  width: 344px;
  margin-left: 15px;
  margin-top: 10px;
}

.rightDiv {
  padding: 0.8rem 0.8rem 0.8rem 4rem;
}

.radioButtonText {
  position: static;
  width: 83px;
  height: 24px;
  left: 32px;
  top: 0px;
  font-family: 'canada-type-gibson';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  color: #00172f;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.textSpacer {
  width: 12px;
}

input[name='itemsearch']:checked {
  background-color: #0071e9;
  accent-color: red;
}

//Search Card style:

.searchCard {
  font-size: 20px;
  background: white !important;
  border: none;
  outline: none;

  .searchTitle {
    display: flex;
    align-items: center;
    // padding-top: 13px;
    margin-left: 3px;

    .title {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      color: #00172f;
      margin-right: 0px;
    }
  }

  .collapseCard {
    margin-left: 3px;

    .insideDiv {
      margin-top: 0px;
      margin-left: 5px;

      .searchCategory {
        width: 100%;
        display: inline-flex;
        height: 32px;

        background-color: white !important;
        align-items: center;
        margin: 0px 0px 0px 10px;
        font-size: 15px;

        .normalButton {
          color: #0071e9 !important;
          background-color: #e5f1fd !important;
          vertical-align: middle;
          text-align: center;
          height: 24px;
          font-size: 14px;
          line-height: 22px;
          cursor: pointer;
        }
        .selectedButton {
          background: red !important;
          color: #002751 !important;
          border-radius: 1px !important;
          box-shadow: 0 0 4px 0 rgba(0, 39, 81, 0.25) !important;
          background-color: #ffffff !important;
          z-index: 5;
          height: 24px !important;
          font-size: 14px;
          line-height: 22px;
          vertical-align: middle;
          text-align: center;
          cursor: pointer;
        }
      }

      .insideFont {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #33577d;
        margin-bottom: 5px;
      }

      .textArea {
        border-radius: 4px;
        border: none !important;
      }
    }
    .MuiCheckbox-colorPrimary {
      background: red;
    }
    .formControl {
      span {
        font-style: normal;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 18px !important;
        color: #00172f !important;
      }
    }
    .dcSelector {
      margin-top: 10px;
      height: 60px;
      margin-bottom: 32px;
      // margin-left: 14px;
      .insideFont {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #33577d;
        margin-left: 7px;
      }
    }

    .searchButton {
      margin: 0 24px;
    }
    .closeButton {
      margin: 0 24px 0 26px; 
    }
  }
}

.multiSelect {
  // margin-left: 6px
}

//Search Result style
.CardOutline {
  font-size: 20px;
  background: #e6eaef !important;
  margin-left: 12px;
  min-width: 336px;
  overflow: visible;
  white-space: nowrap;
  border: none;
  outline: none;

  .searchResultTitle {
    margin-right: 80px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #00172f;
    transform: translateX(-15px);
  }
  .collapseResult {
    background: #ffffff !important;
    overflow: hidden;
    width: 363px;
    border-radius: 4px;
    margin-top: 5px;
    padding-right: 20px;
  }
  .collapseNoResult {
    box-sizing: border-box;
    background: #ffffff !important;
    height: 104;
    overflow: visible;
    width: 387px;
    border-radius: 4px;
    padding-top: 26px;
    padding-bottom: 26px;
    padding-left: 86px;
  }

  .searchResultsHeader {
    text-align: center;
  }

  .topLine {
    width: 339px;
    max-width: 339px;
    height: 1px;
    background: #cddde6;
    margin: 0 12px;
    position: relative;
    top: 12px;
    z-index: 0;
  }

  .resultsTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #597796;
    background: #ffffff;
    padding: 0 12px;
    white-space: nowrap;
    position: relative;
    z-index: 2;
  }

  .searchList {
    overflow: auto;
    max-height: calc(100vh - 560px);
    border-radius: 4px;
  }

  .searchListItemSelected {
    background: #e5f1fd !important;
    color: '#0060C6';
  }

  .searchListItem {
    height: 72px;

    .itemContainer {
      height: 72px;
      // border-bottom: solid #a6cdf7 1px;
      width: 335px;
      display: flex;
      align-items: center;

      .itemImage {
        width: 40px;
        height: 40px;
      }

      .itemInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 12px;
        height: 30px;
      }

      .itemNumber {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #143b50;
      }

      .itemDescribe {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #597796;
      }
      :hover {
        .itemNumber {
          color: #00172f !important;
        }
        .itemDescribe {
          color: #597796 !important;
        }
      }
    }
  }
  .searchListItem:hover {
    background: #f2f5f7;
  }

  .savesearch {
    padding: 8px 25px 16px 25px;
    font-size: 14px;
    color: #0071e9 !important;
    text-align: center !important;
  }
}

// Scrollbar

.searchList::-webkit-scrollbar {
  width: 3px;
}

.searchList::-webkit-scrollbar-thumb {
  background: #a6cdf7;
  height: 237px;
}

@supports (scrollbar-color: #a6cdf7 transparent) {
  .searchList {
    scrollbar-color: #a6cdf7 transparent;
    scrollbar-width: thin;
  }
}

//Saved Search style

.savedSearchCard {
  font-size: 18px;
  background: white !important;
  padding-top: 10px;
  border: none;
  outline: none;
  margin-bottom: 30px;

  .searchTitle {
    display: flex;
    align-items: center;
    padding-top: 13px;
    margin-left: 3px;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #00172f;
      margin-right: 40px;
    }
  }

  // .collapseCard {
  //   background: #ffffff !important;
  //   max-height: 200px;
  //   overflow: auto;
  //   width: 387px;
  //   border-radius: 4px;
  //   margin-top: 16px;
  //   padding: 8px;
  //   margin-left: 20px !important;

  //   .insideDiv {
  //     margin-top: 34px;
  //     margin-left: 5px;
  //   }
  // }
}

.dropdownSubtext {
  position: absolute;
  height: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  margin-left: 8px;
  color: #33577d;
}
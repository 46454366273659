.header {
	min-height: 120px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
	background: #F1F5FB !important;
	box-shadow: inset 0px -1px 0px #B7D8EB !important;
	display: flex;
	flex-direction: row !important;
	align-items: center;
	justify-content: space-between;
}

// Item Display

.itemDisplay {
    display: flex;
    flex-direction: row;
	margin-left: 36px;
    .itemContent {
		display: flex;
		margin-right: 40px;
    }
}

.itemImage {
	width: 56px;
	height: 56px;
	object-fit: cover;
}

.itemInfoContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-left: 16px;
	white-space: nowrap;
}

.itemNumber {
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 32px;
	/* identical to box height, or 100% */
	color: #00172F;
	white-space: nowrap;
	margin-bottom: 8px;
}

.itemDescription {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height, or 129% */
	
	color: #33577D;
}

.itemDetail {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #00172F;
	margin-top: 4px;
}

// Right Toolbar

.rightToolbar {
	display: flex;
	align-items: center;
	margin-left: 8px;
}

// Out Storage Indicator

.outStorageText {
	// font-style: normal;
	// font-weight: 500;
	// font-size: 12px;
	// line-height: 16px;
	/* identical to box height, or 133% */
	color: #002438;
	background: #FFBEBE;
	border-radius: 12px;
	padding: 4px 8px 2px;
	margin-left: 4px;
	white-space: nowrap;
}

// Item Nav

.itemNav {
	display: flex;
	align-items: center;
    >button {
        &:first-child {
            margin-right: 8px;
        }
    }
}

.chevRight {
	transform: rotate(180deg);
}

.chevDown {
	transform: rotate(270deg);
}

.chevUp {
	transform: rotate(90deg);
}

// DC Nav

.dcNav {
	display: flex;
	align-items: center;
	margin-right: 8px;
	margin-left: 24px;
}

.dcNumber {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	/* identical to box height, or 112% */	
	color: #002D5D;
	padding: 0 12px;
	white-space: nowrap;
}

// Calendar

.calendarPlaceholder {
	width: 240px;
	height: 40px;
	background: #FFFFFF;
	border: 1px solid #A2B3BE;
	border-radius: 4px;
	background-image: url('../assets/header_icons/Calendar.svg');
	background-repeat: no-repeat;
    background-position-x: 10px;
	background-position-y: center;
	padding: 8px 8px 8px 40px;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #607480;
	box-sizing: border-box;
}

// Icons (right set)

.iconButtonsContainer {
	display: flex;
	justify-content: space-between;
	margin-right: 26px;
	margin-left: 16px;
	padding-left: 0;
}

// Drop Menu

.dropdown {
	position: absolute;
	padding: 0;
	top: 64px;
	transform: translateX(-56%);
	width: 240px;
	background: #FFFFFF;
	box-shadow: 0px 8px 16px rgba(0, 39, 81, 0.15);
	border-radius: 20px;
	cursor: default;
	z-index: 2;
}

.menuItem {
	display: flex;
	align-items: center;
	list-style-type: none;
	width: 100%;
	padding-left: 24px;
	height: 48px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height, or 129% */

	color: #0071E9;
	cursor: pointer;
}

.dividerLine {
	width: 223px;
	border-bottom: 1px solid #C9DFF9;
	margin-left: 8px;
}

//history nav
.historyNav {
	margin-right: 40px;
}

// ###### END HEADER STYLES #######


.homeIcon {
	width: 37px;
	height: 37.5px;
	object-fit: contain;
	cursor: pointer;
}

.samsLogo {
    margin-left: 38% !important;
}

.headerTitle {
	padding-left: 5px;
  	height: 20px;
	font-size: 1.25rem !important;
	font-weight: normal !important;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.11 !important;
	letter-spacing: normal;
	text-align: center;
	color: #0067a0 !important;
}

.shortText { 
	display: none;
 }

// When resolution <= 1200px, hide full text and show short text
@media (max-width: 1200px) {
	.shortText { 
	display: inline-block;
	 }
	.fullText { 
	display: none;
	font-size: 1.19vw !important;
	 }
}

.headerUserBlock {
  padding-right: 36px;
  margin-left: auto;
  margin-right: 0;
  margin-top: 5px;
}

.displayInitials {
	display: block;
	float: left;
	height: 34px;
	width: 34px;
	line-height: 34px;
	margin: 0px 11px;
	border-radius: 50%;
	background-color: #5da3ed;
	color: white;
	text-align: center;
	font-family: Helvetica;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
}

.displayName {
	width: 128px;
	height: 20px;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	color: #424242;
}

.signOut {
	margin-left: 56px;
	width: 55px;
	height: 24px;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
  color: #0071e9;
  display: block;
  cursor:pointer;
}

.footer {
	border-color: #979797;
	border-width: 1px 0px 0px 0px;
	border-style: solid;
	background-color: #f2f5f7;
	height: 63px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.footerText {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #00172f;
  padding: 24px 36px 23px 0;
}

.MuiTooltip-popper {
	background-color: blue !important;
}

.iconDisabled {
	opacity: 0.5;
}
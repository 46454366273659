.bookmarkHeader {
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 24px;
    .bookmarkIcon {
        display: flex;
        flex: 0.1;
    }
    .bookmarkOption {
        display: flex;
        flex: 0.1;
        justify-content: center;
        span {
            padding: 13px 22px;
            cursor: pointer;
            border-radius: 2px;
            &:hover {
                background: #ffffff;
            }
        }
    }
    .bookmarkHeading {
        display: flex;
        flex: 0.8;
        flex-direction: column;
        .headerTextCss {
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            color: #323A3E;
            margin: 0;
        }
        .headerSubtextCss {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #597796;
            margin: 0;
        }
    }
}

.bookmarkDisabled {
    opacity: 0.3;
    span {
        cursor: default !important;
        &:hover {
            background: transparent !important;
        }
    }
}

.bookmarkForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-bottom: 4px;
    .textBox {
        display: flex;
        flex: 0.9;
        padding: 8px 16px;
        border: 1px solid #A2B3BE;
        border-radius: 4px;
        background: #ffffff;
        &:focus-within {
            box-shadow: 0 0 3px 1px #0071E9;
            outline-width: 0px;
        }
    }
    input {
        padding: 0;
        &::placeholder {
            font-family: "canada-type-gibson";
            color: #607480;
        }
    }
    button {
        flex: 0.1;
        height: 40px;
        margin-left: 16px;
        box-sizing: border-box;
        padding: 8px 16px;
        border: 2px solid #BBC8D1;
        border-radius: 4px;
        text-transform: capitalize;
        color: #0071E9;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        font-family: "canada-type-gibson";
        &:hover {
            border: 2px solid #BBC8D1;
            background: rgba(0, 0, 0, 0.04);
        }
    }
}

.bookmarkListWrapper {
    display: flex;
    overflow: auto;
    margin-bottom: 80px;
    margin-top: 2px;
    .bookmarkList {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-height: 495px;
        .bookmarkItem {
            display: flex;
            align-items: center;
            padding: 20px 24px;
            box-shadow: inset 0px -1px 0px #CCE2FB;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: #142B3C;
            &:hover {
                cursor: pointer;
                background: #f2f5f7;
            }
        }
        .boomarkItemEdit {
            cursor: default;
            &:hover {
                cursor: default !important;
                background: transparent !important;
            }
        }
    }
}

.bookmarkBottomPanel {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 350px;
    padding: 16px 24px;
    background: #F1F4F6;
    box-shadow: 0px -8px 16px rgba(0, 39, 81, 0.15);
    button {
        font-family: 'canada-type-gibson';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-transform: capitalize;
        border-radius: 4px;
    }
    .secondaryBtn {
        border: 2px solid #0071E9;
        color: #0071E9;
    }
    .primaryBtn {
        color: #ffffff;
        background: #0071E9;
        margin-left: 16px;
        width: 168px;
        box-shadow: none;
        &:hover {
            background: rgb(0, 79, 163);
            box-shadow: none;
        }
    }
}

.bookmarkListWrapper::-webkit-scrollbar {
    width: 3px;
}

.bookmarkListWrapper::-webkit-scrollbar-thumb {
    background: #a6cdf7;
    height: 237px;
}

.bookmarkOptionMenu {
    ul {
        width: 288px;
        background: #F1F4F6;
        padding: 0;
    }
    .bookmarkOptionitem {
        font-family: 'canada-type-gibson' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #0060C6 !important;
        padding: 16px 12px;
    }
}

.bookmarkDivider {
    height: 1px;
    background: #C9DFF9;
    margin: 0 8px;
}

.bookmarkCheckbox {
    padding: 0 !important;
    margin-right: 16px !important;
    width: 16px;
    height: 16px;
}

.error {
    position: absolute;
    top: 41px;
    font-size: 13px;
    color: #b44714;
    left: 0;
}
.headStyle{
    background: #d9e0e7 !important;
}
.headRow{
    border-right: solid 1px #979ca1;
}
.weekInfo{
    font-size: 20px !important;
    padding: 15px 0px;
    width: 100%;
    text-align: center;
}
.rowTitle{
    text-align: left;
    padding: 16px 2px 16px 32px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #00172F !important;
    text-transform: capitalize !important;
}
.redCell{
    border-radius: 8px;
    background-color: #f6ddd1;
    width: 64px;
    height: 57px;
    font-size: 18px !important;
    font-weight: 500 !important;
    display: inline-grid;
    align-items: center;
}
.redNormalCell{
    font-size: 18px !important;
    font-weight: 500 !important;
    padding: 0px !important;
    vertical-align: center !important;
}
.blueCell{
    border-radius: 8px;
    background-color: #cce2fb;
    width: 64px;
    height: 57px;
    font-size: 18px !important;
    font-weight: 500 !important;
    display: inline-grid;
    align-items: center;
}
.normalCell{
    font-size: 18px !important;
    font-weight: 500 !important;
}
.saveButton {
    font-size: 16px !important;
  font-weight: 500 !important;
}
.commentIcon {
    cursor: pointer;
}
.tabHeader {
    color: #00172F;
    border-bottom: .1rem #cae0f9 solid;
    box-shadow: inset -1px 0px 0px #CAE0F9, inset 0px 1px 0px #FFFFFF, inset 0px -2px 0px #CAE0F9;
}
.calendarWeek {
    color: #00172F;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
}
.calendarWeekDay {
    color: #00172F;
    padding-left: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
}
.tabFirstColumn {
    vertical-align: top !important;
    padding-left: 34px !important;
    width: 24rem !important;
}
.dataColumn {
    text-transform: capitalize;
    color: #33577D !important;
    background: #F3F9FF;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}
.totalColumn {
    color: #33577D !important;
}
.dataColumnRed {
    background: #FAEDE7;
    color: #33577D !important;
}
.dataColumnBlue {
    background: #D9EAFC;
    color: #33577D !important;
}
.rowData {
    border-bottom: .1rem #cae0f9 solid;
}
.textDim {
    color: #99ABBE !important;
}
.borderLeftRight {
    border-left: 2px solid #16914E;
    border-right: 2px solid #16914E;
}
.borderTop {
    border-top: 2px solid #16914E;
}
.borderBottom {
    border-bottom: 2px solid #16914E !important;
}
.moveLeft {
    padding-left: 34px !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input{
  width: auto !important;
}

.MuiInputBase-root {
  align-items: flex-start !important;
  width: 100% !important;
  display: block !important;
  clear: left !important;
  float: left !important;
}

/* .MuiFormControl-root{
   
} */
.MuiFormHelperText-root.Mui-error{
  color: #b44714 !important;
}
.MuiFilledInput-root {
  padding: 10px !important;
  background-color: white !important;
  border:none ;
  border-radius: 4px !important;
  max-height: 135px !important;
  overflow: scroll !important;
  color: red !important;
}
.MuiFilledInput-root.Mui-error{
  border: 2px solid #d35318 !important;
  background-color: #faede7 !important;
}
.MuiFormHelperText-contained{
  padding-top: 5px !important;
  margin: 0px !important;
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input{
  color: #33577D;
}
.clearButton{
  padding: 2px;
  width: 29px;
  height: 16px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #0071e9;
  cursor: pointer;
}

.autoCompleteCss{
  height: 160px;
  background: #FFFFFF;
  border: 1px solid #CCE0EC;
  /* box-shadow: 0px 0px 0px 2px #00AFD7, 0px 0px 12px rgba(0, 175, 215, 0.75); */
  border-radius: 3px;
  margin-left: 2px !important
}

.MuiAutocomplete-tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;

  
  /* Grey/10 */
  
  background: #F1F4F6;
  border-radius: 16px; 
}

.MuiAutocomplete-tag > .MuiChip-deleteIcon {
  color: #1A426D;
}

.MuiAutocomplete-tag > .MuiChip-label {
  
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */

display: flex;
align-items: center;

/* Brand/brand-darken-80 */

color: #142B3C;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

#autocomp .MuiInputBase-root {
  overflow: auto;
}

#autocomp .MuiInputBase-root::-webkit-scrollbar {
  width: 3px;
}

#autocomp .MuiInputBase-root::-webkit-scrollbar-thumb {
  background: #a6cdf7;
  height: 50px;
}
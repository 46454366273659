.hostCom {
    padding: 18px 0px 0px 0px;
}
.regCom {
    padding: 18px 0px 18px 0;
}

.addButton {
    padding-top: 18px;
    padding-left: 20px;
}

.textEditor {
    display: flex;
    flex-wrap: 'wrap';
    flex-direction: column;
}
.textBox {
    padding: 10px;
    border-width: 1px;
    border-color: #0071E9;
    border-style: solid;
    border-radius: 3px;
    flex: 9;
}
.saveButton {
    padding: 0px 20px;
    flex: 1;
    text-transform: 'none';
}

.closeButton {
    padding: 0px 10px ;
    text-transform: none !important;
    color: #0071E9 !important;
    &:hover{
        background-color: transparent;
    }
}

.commentHead {
    flex: 3;
    font-size: 18px !important;
    font-weight: 500 !important;
    padding-left: 30px;
}

.hostCommentText {
    flex: 14;
    width: 800px;
    overflow-wrap: break-word;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #606C7D;
}

.regCommentText {
    flex: 14;
    width: 800px;
    overflow-wrap: break-word;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #606C7D;
}

.noteText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #00172F;

    svg {
        margin-left: 9px;
        cursor: pointer;
    }
}

.noteButtons {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 20px;

    button {
        width: 30%;
        border-radius: 3px;
    }
}

.iconDisabled {
    opacity: 0.3;
    cursor: auto !important;
}
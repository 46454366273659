body {
  margin: 0;
  font-family: "canada-type-gibson", "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul#customAutoComplete-popup li {
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #BBC8D1;
    border-radius: 12px 12px 0px 0px;
    flex: none;
    order: 0;
    flex-grow: 1;
    font-family: 'canada-type-gibson';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px; 
    color: #142B3C;
} 

ul#customAutoComplete-popup {
    max-height: 25.6vh;
}
.MuiInputBase-root div[role=button]+span+input {
    position: absolute;
    right: 45px;
}

#search-multiselect .MuiAutocomplete-root[aria-expanded=false] .MuiInputBase-root {
    height: 40px;
}
#search-multiselect .Mui-focused .MuiInputBase-root {
    height: auto !important;
    z-index: 9;
}
#search-multiselect .MuiAutocomplete-root[aria-expanded=true] .MuiInputBase-root {
    z-index: 9;
}
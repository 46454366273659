.loadingStyle{
  display: block;
  height: 312px;
}
.itemCard{
  flex-grow: 1;
  padding: 0px 20px 0px 40px;
  

  .DcFont {
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 16px;
    line-height: 18px;
    color: #33577D;
    padding-top: 31px;
  }

  .titleWeekCard{
    // display: flex; 
    flex-direction: row;
    margin-bottom: 27px;

    .ItemFontTitle{
      letter-spacing: normal;
      display: block; 
      width: 237px;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      float: left;
      align-items: center;
      padding-top: 12px;
      color: #00172F;
      // margin-right: 150px;
      
    }
    .weekCard{
      float: right;
    }

  }

  .itemDetails{
      margin-top: 27px;
    .imgPaper{
      box-sizing: border-box;
      display: block;
      width: 128px;
      height: 128px;
      text-align:center; 
      padding: 13px 13px 13px 13px; 
      float: left;
    }
    
    .itemDetailRightDard{
      padding: 0px 20px 10px 20px;
      float: left;
      width: 80%  !important;

      .rightArrow {
        float: left;
        color: #33577D;
        height: 18px;
        width: 18px;
        transform: translateX(-4px)
      }

      .detailFont{
        padding-top: 1px;
        float: left;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #33577D;
      }

      .detailTitle{
        padding-top: 11px;
        text-align: left;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #00172F;
      }

      .tableContainer{
          transform: translateX(-15px);
          margin-top: 10px; 
          flex-wrap: wrap;
          display: flex;
          margin-left: 16px;
          text-align: left;
          
          .tableitem{
            float: left;
            display: block;
            width: 430px !important;
            height:21px;
            overflow: hidden;
            padding: 3px 0;
            .tableitemTitle{
              display: block;
              width: 114px;
              height:21px;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              color: #00172F;
              float: left;
            }
            .tableitemDetail{
              display: block;
              width: 316px;
              height:21px;
              float: left;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #00172F;
            }
          }
      }
    }
  }
}


@media (min-width: 960px) {
  
  .itemDetailRightDard{
    width: 70% !important;
    
    .tableitemDetail{
      width: 200px;
      overflow: hidden
    }      
  }

}

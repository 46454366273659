
.Leftbar {
    background: #E6EAEF !important; 
    min-width: 271px;
    min-height: 92vh;
    width: 17%;
    text-align: -webkit-center;
}
.RightGrid {
    background: #fff;
    min-width: 400px;
    width: 100%;
    min-height: 92vh;
}
.sidenavHeader {
   display: flex;
   flex-direction: column;
}

.drawerHeader {
    display: flex;
    width: 387px;
    height: 80px;
    background-color: #F7F8F9;
    align-items: center;
    // width: 20px;
    // height: 14px;  
    // top: 33px;
    // margin-left:13px
 }

 .unionIcon {
     margin-left: 24px;
 }

 .headerTextContainer {
     display: flex;
     flex-direction: column;
     margin-left: 16px;
 }

 .headerTextCss{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    padding-bottom: 3px;
    /* identical to box height, or 120% */
    color: #00172F;
    // white-space: nowrap;
}

.headerSubtextCss{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    padding-top: 2px;
    /* identical to box height, or 133% */
    color: #597796;
}

// .resetButton { 
//     font-weight: 500;
//     font-size: 14px;
//     /* identical to box height, or 171% */
//     color: #0071E9;
//     margin-left: 0px;
// }

.spacer {
    height: 15px;
}

.searchButton{
    font-family: canada-type-gibson;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    // background: #0071E9 !important;
    border-radius: 3px;
    // color: white !important;
    margin: 20px 6px; 
    height: 48px; 
    width:160px;
}
.closeButton{
    font-family: canada-type-gibson;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color:black;
    text-transform: none;
    margin: 20px 6px; 
    height: 48px; 
    width:160px;
}
 .margintop10px{
     margin-top: -10px;
 }
  @media (min-width: 1650px) {
    .Leftbar {
        background: #E6EAEF !important; 
        min-width: 271px;
        min-height:  92vh;
        width: 7%;
        text-align: -webkit-center;
    }
    .RightGrid {
        background: #fff;
        min-width: 400px;
        width: 100%;
       
    }
  }
  @media (max-width: 1340px) {
    .Leftbar {
        background:  #E6EAEF !important; 
        min-width: 271px;
        min-height:  92vh;
        width: 17%;
        text-align: -webkit-center;
    }
    .RightGrid {
        background: #fff;
        min-width: 400px;
        width: 100%;
       
    }
  }
  @media (max-width: 1250px) {
    .Leftbar {
        background: #E6EAEF !important; 
        min-width: 271px;
        min-height:  92vh;
        width: 17%;
        text-align: -webkit-center;
    }
    .RightGrid {
        background: #fff;
        min-width: 400px;
        width: 100%;
        
    }
  }
  @media (max-width: 1170px) {
    .Leftbar {
        background: #E6EAEF !important; 
        min-width: 271px;
        min-height:  92vh;
        width: 17%;
        text-align: -webkit-center;
    }
    .RightGrid {
        background: #fff;
        min-width: 400px;
        width: 100%;
       
    }
}
@media (max-width: 1060px) {
    .Leftbar {
        background: #E6EAEF !important; 
        min-width: 271px;
        min-height:  92vh;
        width: 17%;
        text-align: -webkit-center;
    }
    .RightGrid {
        background: #fff;
        min-width: 400px;
        width: 100%;
    }
}
.resetButton { 
      position: fixed;
      height: 24px;
      top: 20px;  
      font-family: 'Canada-type-gibson';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */
      
      text-align: right;
      color: #0071E9;
      margin-left:40px;
}

.resetButton:hover {
    cursor: pointer;
}
.calendar {
    font-family: "canada-type-gibson";
    padding: 16px;
    background: #F1F4F6;
    box-shadow: 0px 8px 24px rgba(0, 39, 81, 0.15);
    .calendarHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .headerText {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #0067A0;
        }
        .iconWrapper {
            display: flex;
            svg {
                height: 14.73px;
                cursor: pointer;
                &:first-of-type {
                    margin-right: 33px;
                }
            }
        }
    }
    .divider {
        height: 0px;
        border-bottom: 1px solid #BBC8D1;
        margin-top: 8px;
    }
    .dayNamesWrap {
        display: flex;
        justify-content: space-around;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #323A3E;
        text-transform: capitalize;
        padding: 16px 0 8px 0;
    }
    .row {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        .col {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 0;
            flex-basis: calc(100%/7);
            width: calc(100%/7);
            padding: 16px 0px;
            height: 48px;
            background: #ffffff;
            overflow: hidden;
            cursor: pointer;
            box-sizing: border-box;
            .number {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #0067A0;
            }
            &:hover {
                border: 1px solid #0067a080;
                border-radius: 4px;
            }
        }
        .disabled {
            background: #D5DEE4;
            pointer-events: none;
            .number {
                color: #516069;
            }
        }
        .selected {
            border: 2px solid #0067A0;
            border-radius: 4px;
            &:hover {
                border: 2px solid #0067A0;
            }
        }
    }
    .bottomWrap {
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        padding-bottom: 8px;
        button {
            width: 96px;
            padding: 8px 0px;
            background: #ffffff;
            border: 0px;
            border-radius: 4px;
            cursor: pointer;
            font-family: "canada-type-gibson";
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
            color: #323A3E;
            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }
            &:focus-visible {
                outline: none;
            }
        }
    }
}

.arrowRight {
    transform: rotate(180deg);
}

.dim {
    opacity: 0.5;
    pointer-events: none;
}

.disabledColor{
    color: #51606980 !important;
}

button{
    &:disabled{
        pointer-events: none;
        opacity:0.5;
    }
}
.DcListBorder {
	border-top: 2px solid #D9D9D9;
	border-bottom: 2px solid #D9D9D9;
	height: 100%;
	width: 100%;
	box-sizing: content-box;
	display: flex;
	flex-wrap: wrap;
	// margin-bottom: 10px;
}

.DcFormControlLabelBorder {
	box-sizing: border-box;
	width: 12.5%;
	margin: 0;
	padding: 0;
	height: 58px;
}

.dcCheckbox {
	border-radius: 1px;
	border: 2px solid #090809;
	box-sizing: border-box;
}

.downloadFormControlLabel {
	height: 24px;
	width: 102px;
	padding-left: 0px;
	padding-top: 14px;
	margin-left: 0px;
}

.formControlLabelText {
	font-family: 'canada-type-gibson' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
}

.errorMessageBox {
	background-color: #FDE7E6;
	border-radius: 2px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	margin-top: 16px;
	margin-bottom: 34px;
}

.errorMessage {
	padding-left: 8px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}

.errorIcon {
	padding-left: 16px;
	padding-top: 12px;
	padding-bottom: 12px;
	height: 16px;
	width: 16px;
}

.dialogTitle {
	font-family: 'canada-type-gibson' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 20px !important;
	line-height: 20px !important;
	color: #323A3E !important;
	margin: 0 !important;
}

.dialogSubtitle {
	font-family: 'canada-type-gibson' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	color: #516069 !important;
	margin: 0 !important;
	position: relative;
	top: -5px;
	// margin-top: 0px !important;
}

.dialogActions {
	margin-top: 24px !important;
	margin-bottom: 10px !important;
}

#DownloadButton {
	margin-left: 40px !important;
}

.buttonSpacer {
	width: 0px;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}
.mainContent {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #323A3E;
}
.subContent {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-top: 16px;
    width: 43%;
    color: #323A3E;
}
.iconContainer {
    position: relative;
    top: 0;
    left: 0;
}
.ellipseIcon {
    width: 100%;
    height: auto;
    max-width: 180px;
    max-height: 180px;
    position: relative;
    top: 0;
    left: 0;
}
.mainRectangle {
    position: absolute;
    top: 51.89px;
    left: 34.76px;
    width: 108.5px;
}
.midRectangle {
    position: absolute;
    top: 28px;
    left: 43px;
}
.frontRectangle {
    position: absolute;
    top: 39px;
    left: 40px;
}
.folderFileWhite {
    position: absolute;
    top: 65px;
    left: 35px
}
.folderFile {
    position: absolute;
    top: 69px;
    left: 35px;
}
.searchFileIcon {
    position: absolute;
    width: 70px;
    height: 73px;
    top: 105px;
    left: 107px;
}
.searchFilter {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #0067A0;
    padding-top: 16px;
    cursor: pointer;
}